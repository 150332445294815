<template>
  <ClientOnly>
    <LazyAdSlot
      v-if="isGooglePublisherTagConsentGiven && slotConfig"
      v-bind="{ ...slotConfig }"
      :slot-name
    />
  </ClientOnly>
</template>

<script setup lang="ts">
interface Props {
  id?: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename?: 'CmsAdSlotBlock'
  slotName: string
}

const { slotName } = defineProps<Props>()

const appConfig = useAppConfig()
const slotConfig = computed(() => appConfig?.adSlots?.[slotName] ?? {})
const { getConsentForPurpose } = useConsentManager()
const isGooglePublisherTagConsentGiven = computed(() =>
  getConsentForPurpose(CONSENT_MANAGER_PURPOSE.ADVERTISING)
)

defineOptions({
  name: 'CmsBlockAdSlot',
})
</script>
