<template>
  <div :class="context.classes.outer">
    <div :class="context.classes.wrapper">
      <label
        v-if="context.label"
        :for="context.id"
        :class="context.classes.label"
      >
        {{ context.label }}
      </label>
      <div :class="context.classes.inner" class="step-number-input">
        <button
          v-if="showDeleteIcon"
          :class="context.classes.decrement"
          @click="handleDecrement"
        >
          <InlineSvg
            :name="TRASH_CAN_OUTLINE"
            class="dark:fill-light fill-dark size-5"
          />
        </button>
        <button
          v-else
          :class="[
            context.classes.decrement,
            { 'pointer-events-none': isDecrementDisabled },
          ]"
          :disabled="isDecrementDisabled"
          type="button"
          @click="handleDecrement"
        >
          <InlineSvg
            :name="MINUS"
            class="size-5 fill-current"
            :class="{ 'opacity-25': isDecrementDisabled }"
          />
        </button>
        <input
          :id="context.id"
          :name="context.name"
          type="number"
          :class="context.classes.input"
          v-bind="context.attrs"
          :disabled="context.disabled"
          :value="context._value"
          @input="handleInput"
          @blur="context.handlers.blur"
          @focus="handleFocus"
        />
        <button
          :class="[
            context.classes.increment,
            { 'pointer-events-none': isIncrementDisabled },
          ]"
          type="button"
          :disabled="isIncrementDisabled"
          @click="handleIncrement"
        >
          <InlineSvg
            :name="PLUS"
            class="size-5 fill-current"
            :class="{ ' opacity-25': isIncrementDisabled }"
          />
        </button>
      </div>
    </div>
    <div v-if="context.help" :class="context.classes.help">
      {{ context.help }}
    </div>
    <div v-if="context.message" :class="context.classes.message">
      {{ context.message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { SVG_ICON } from '@assets/constants/inline-svg'
const { PLUS, MINUS, TRASH_CAN_OUTLINE } = SVG_ICON

interface Props {
  context: any
}

const { context } = defineProps<Props>()

const showDeleteIcon = computed(() => {
  return (
    context.deletable &&
    parseInt(context._value, 10) <= parseInt(context.min, 10)
  )
})

const isDecrementDisabled = computed(() => {
  return (
    context.disabled ||
    (!context.deletable &&
      parseInt(context._value, 10) <= parseInt(context.min, 10))
  )
})

const isIncrementDisabled = computed(() => {
  return (
    context.disabled ||
    parseInt(context._value, 10) >= parseInt(context.max, 10)
  )
})

function createStepHandler(step: number) {
  return function () {
    const value = parseInt(context._value, 10) ?? 0
    const newValue = value + step

    if (context.disabled) {
      return
    }

    if (step > 0 && context.max && newValue > context.max) {
      return
    }

    context.node.input(newValue)
  }
}

function handleIncrement() {
  createStepHandler(context.step ?? 1)()
}

function handleDecrement() {
  createStepHandler(-(context.step ?? 1))()
}

function handleInput(event: Event) {
  const input = event.target as HTMLInputElement
  const value = input.value

  const numValue = parseInt(value, 10)
  const min = parseInt(context.min, 10) || 0
  const max = parseInt(context.max, 10) || Infinity
  const step = parseFloat(context.step) || 1

  if (isNaN(numValue)) {
    input.value = min.toString()
    context.node.input(min)
    return
  }

  let newValue = numValue

  // Check if the new value is 0 and deletable is set
  if (context.deletable && newValue === 0) {
    input.value = '0'
    context.node.input(0)
    return
  }

  // Check if the new value is below the minimum
  if (newValue < min) {
    newValue = context.deletable ? 0 : min
  }

  newValue = Math.max(min, Math.min(max, newValue))
  newValue = Math.round((newValue - min) / step) * step + min
  input.value = newValue.toString()
  context.node.input(newValue)
}

function handleFocus(event: FocusEvent) {
  const input = event.target as HTMLInputElement
  input.select()
}
</script>
