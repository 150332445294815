import { default as _91_46_46_46pageSlug_930IfITNWUDBMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activateGYz83PvgXqMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/activate.vue?macro=true";
import { default as indexlaBQ3OaRTsMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93fbjXmkLW5xMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as neweAZwEaWexVMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistVb91OdamppMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/watchlist.vue?macro=true";
import { default as faqAwI3T0SKlvMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as indexMQNkbhhfZ4Meta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue?macro=true";
import { default as info6kHs36QKksMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as index6wOpjhN68jMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue?macro=true";
import { default as _91showGroupSlug_93HWDotnpWXaMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programWUL4gkgzMkMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopfE3kpiHq9QMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93X2RNXlIObuMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as indexHQ97H9VTmBMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_93zoAQfly0t7Meta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as vouchersMnJlZxOkvLMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93pq6GFljbMPMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexkboviZTbhwMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93FFlKAxG1H2Meta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasX4220YZw6ZMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinemas.vue?macro=true";
import { default as citieshAluXBmTtkMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cities.vue?macro=true";
import { default as index3cVAz735fcMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/index.vue?macro=true";
import { default as indexiCYh3qX7EwMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as _91movieSlug_93S1FxSAQcjYMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexkdNiSXXrJzMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93Va1RaMtOZhMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genres8Ox0r6Ff4iMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexTCpEONI5JtMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/index.vue?macro=true";
import { default as index4A4dDKkjJ8Meta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/person/[personSlug]/index.vue?macro=true";
import { default as _91personSlug_93xNTAg93qbmMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsF5PzTm8erjMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/persons.vue?macro=true";
import { default as searchJ8akg0do2NMeta } from "/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/search.vue?macro=true";
export default [
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_93FFlKAxG1H2Meta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93pq6GFljbMPMeta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "",
    meta: indexMQNkbhhfZ4Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93HWDotnpWXaMeta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93zoAQfly0t7Meta?.name,
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93FFlKAxG1H2Meta?.name,
    path: "/kino/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93pq6GFljbMPMeta?.name,
    path: "/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "",
    meta: indexMQNkbhhfZ4Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93HWDotnpWXaMeta?.name,
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93zoAQfly0t7Meta?.name,
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93FFlKAxG1H2Meta?.name,
    path: "/es/cine/:citySlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: _91cinemaSlug_93pq6GFljbMPMeta?.name,
    path: "/es/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "",
    meta: indexMQNkbhhfZ4Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/es/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: _91showGroupSlug_93HWDotnpWXaMeta?.name,
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/es/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93zoAQfly0t7Meta?.name,
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/es/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/es/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91movieSlug_93S1FxSAQcjYMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93S1FxSAQcjYMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93S1FxSAQcjYMeta?.name,
    path: "/es/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93Va1RaMtOZhMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93Va1RaMtOZhMeta?.name,
    path: "/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93Va1RaMtOZhMeta?.name,
    path: "/es/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: _91personSlug_93xNTAg93qbmMeta?.name,
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93xNTAg93qbmMeta?.name,
    path: "/person/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91personSlug_93xNTAg93qbmMeta?.name,
    path: "/es/persona/:personSlug",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "person-personSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/person/[personSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2024-10-01_08-02-09_f01ae01/layers/portal/pages/search.vue").then(m => m.default || m)
  }
]