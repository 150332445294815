export default {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    outer: `
      group/form text-neutral-dark-1 dark:text-neutral-light-1
      formkit-disabled:opacity-30 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none
    `,
    label: `$reset label-floating`,

    wrapper: `
      relative rounded border border-neutral-dark-1 dark:border-neutral-light-1 w-full
      group-data-[invalid]/form:border-system-error
    `,
    input: `$reset input input-hover border-none`,
    help: 'text-xs mt-1 text-neutral-dark-2 dark:text-neutral-light-2',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-system-error text-xs mt-1',
    messages: 'list-none p-0 mb-0',
    prefixIcon: `
      w-10 flex self-stretch grow-0 shrink-0
      rounded-tl rounded-bl border-r border-gray-400
      bg-white bg-gradient-to-b from-transparent to-gray-200
      [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto
    `,
    suffixIcon: `
      w-7 pr-3 p-3 flex self-stretch grow-0 shrink-0
      [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto
    `,
  },

  email: {
    inner: '$reset h-full',
    input:
      '$reset block align-middle w-full inline-block text-lg appearance-none bg-transparent border-none outline-none text-dark dark:text-light h-full py-3 px-3 placeholder-neutral-dark dark:placeholder-neutral-light focus:placeholder-dark dark:focus:placeholder-light dark:focus:bg-light dark:focus:text-black rounded-sm',
  },

  password: {
    inner: '$reset h-full',
    input:
      '$reset block align-middle w-full inline-block text-lg appearance-none bg-transparent border-none outline-none text-dark dark:text-light h-full py-3 px-3 placeholder-neutral-dark dark:placeholder-neutral-light focus:placeholder-dark dark:focus:placeholder-light dark:focus:bg-light dark:focus:text-black rounded-sm',
  },

  form: {
    message: '$reset',
    messages: 'list-none p-0 mb-0',
  },

  'family:button': {
    input: '$reset formkit-family-button-input',
    wrapper: '$reset',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },

  'family:text': {
    wrapper: 'h-14',
    input: 'pt-6 pb-2 px-3',
  },

  textarea: {
    input: 'h-32 px-3 pt-6 pb-2',
    label: 'overflow-hidden',
  },

  'family:box': {
    decorator: `
      block relative h-5 w-5 mr-2 rounded
      bg-transparent border border-neutral-dark-2 dark:border-neutral-light-2
      peer-checked:text-primary
      [&>span>svg]:opacity-0 peer-checked:[&>span>svg]:opacity-100 cursor-pointer
    `,
    decoratorIcon:
      '$reset flex p-[3px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    input:
      'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    label:
      '$reset select-none cursor-pointer flex-grow space-x-2 text-dark dark:text-light hover:text-neutral-dark-1 dark:hover:text-neutral-light-1',
    wrapper: 'flex items-center dark:text-light text-dark',
  },

  'family:dropdown': {
    // pointer-events-none for the label are crucial
    // to allow the dropdown button to be clickable
    label: '!w-auto pointer-events-none',
    wrapper: `h-14 w-full rounded relative`,
    dropdownWrapper: `$reset
      my-2 w-full shadow-lg rounded relative [&::-webkit-scrollbar]:hidden
      bg-neutral-light-2 dark:bg-neutral-dark-2 dark:text-light text-dark
    `,
    emptyMessageInner: `
      flex items-center justify-center text-sm p-2 text-center w-full text-dark
      [&>span]:mr-3 [&>span]:ml-0
    `,
    listbox: 'shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem: `
      pl-7 w-full relative
      hover:bleed-neutral-light-1/25 dark:hover:bleed-neutral-dark-1/25 bleed-bg py-1
      data-[is-active="true"]:aria-selected:text-primary aria-selected:text-primary
    `,
    loaderIcon: 'ml-auto',
    loadMoreInner: `
      flex items-center justify-center text-sm p-2 text-center
      w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer
      [&>span]:mr-3 [&>span]:ml-0
    `,
    selectionWrapper: 'w-full mt-4',
    selection: 'w-full',
    option: `
      px-3 py-1 w-full text-left text-dark dark:text-light
    `,
    placeholder: 'px-3 py-2.5 text-dark dark:text-light',
    selector: 'h-14 flex justify-between items-center input input-hover',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon:
      'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em]',
  },

  radio: {
    fieldset: '$reset',
    legend: '$reset label',
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
    options: 'space-y-2',
    wrapper: '$reset flex items-start gap-2',
  },

  checkbox: {
    wrapper: '$reset flex items-start gap-2',
    decorator: 'mt-0.5',
  },

  colorpicker: {
    inner: 'relative inline-flex',
    label: '!text-sm !pt-1 !font-semibold',
    swatchPreview:
      'flex justify-start items-center px-3 mt-6 cursor-pointer outline-none',
    canvasSwatchPreviewWrapper: `
      relative before:rounded-sm before:z-[2]
      before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full
    `,
    canvasSwatchPreview: `
      rounded aspect-[2/1] !w-10 border border-neutral
    `,
    valueString: 'inline-block ml-2 mr-1 text-dark dark:text-light',
    panel: `
      flex flex-col max-w-[300px] p-2 rounded
      bg-white touch-manipulation absolute w-[100vw]
      top-full left-0 border shadow-xl z-10
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0
      [@media(max-width:431px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none
    `,
    panelClose: `
      flex justify-end items-center mb-1 -mt-1 border-none bg-none
      border-b w-[calc(100%+1rem)] -ml-2 pt-0 pr-2 pb-1 pl-2
    `,
    closeIcon: `
      w-[2rem] aspect-square p-1 rounded-full border
      [&>svg]:w-full [&>svg]:aspect-square [&>svg]:max-w-none [&>svg]:max-h-none
    `,
    controlGroup: `grid [grid-template-areas:'a_a_a'_'b_c_e'_'b_d_e'] mb-2`,
    canvas: `block w-full`,
    canvasLS: `aspect-[2/1] cursor-pointer rounded-sm`,
    LS: `[grid-area:a] relative mb-2`,
    preview: `
      [grid-area:b] w-8 inline-flex relative rounded overflow-hidden
      aspect-square rounded-sm
      after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full
      after:rounded-sm after:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]
    `,
    hue: `[grid-area:c] relative inline-flex h-3/4 ml-2`,
    alpha: `[grid-area:d] relative inline-flex h-3/4 ml-2`,
    eyeDropper: `[grid-area:e] p-1.5 ml-2 inline-flex self-center justify-self-center aspect-square rounded-sm border cursor-pointer content-center items-center`,
    eyeDropperIcon: `w-auto [&>svg]:w-5`,
    control: `absolute bg-white shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)] -translate-x-1/2 -translate-y-1/2 pointer-events-none data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)] focus-visible:outline-none focus-visible:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2),0_0_0_4px_rgba(59,130,246,1),0_0_0_5px_rgba(0,0,0,1)]`,
    controlLS: `w-[10px] h-[10px] rounded-full`,
    controlHue: `w-[4px] h-[calc(100%-2px)] top-1/2 rounded-[1px]`,
    controlAlpha: `w-[4px] h-[calc(100%-2px)] top-1/2 rounded-[1px]`,
    formatField: `
      flex
      items-center
      justify-center
      grow
    `,
    colorInputGroup: `
      flex
      items-center
      justify-center
      grow
    `,
    fieldGroup: `
      flex
      flex-col
      items-center
      justify-center
      w-full
      mr-1
      [&>input]:p-1
      [&>input]:text-sm
      [&>input]:m-0
      [&>input]:grow
      [&>input]:shrink
      [&>input]:w-full
      [&>input]:border
      [&>input]:rounded-sm
      [&>input]:text-center
      [&>input]:appearance-none
      [&>input::-webkit-outer-spin-button]:appearance-none
      [&>input::-webkit-inner-spin-button]:appearance-none
      [&>input::-webkit-inner-spin-button]:m-0
      [&>input:focus]:outline-none
      [&>input:focus]:ring
      [&>input:focus]:ring-blue-500
      max-[431px]:[&>input]:text-base
    `,
    fieldLabel: `
      text-xs
      mt-1
      opacity-50
    `,
    formatSwitcher: `
      !hidden
      flex
      justify-end
      self-start
      mt-1
      uppercase
      shrink-0
      p-1
      rounded-sm
      select-none
    `,
    switchIcon: `
      [&>svg]:w-3
    `,
    swatches: `
      flex
      flex-wrap
      w[calc(100%+0.5rem)]
      -ml-1
      pt-2
      pb-2
      mt-2
      -mb-2
      border-t
      overflow-auto
      max-h-[200px]
      select-none
      first:-mt-2
      first:border-t-0
    `,
    swatchGroup: `
      flex
      flex-wrap
      w-full
      mb-2
      last:mb-0
    `,
    swatchGroupLabel: `
      block
      w-full
      text-sm
      opacity-50
    `,
    swatch: `
      relative
      w-full
      max-w-[calc((100%/10)-0.5rem)]
      aspect-square
      m-1
      cursor-pointer
      before:content-['']
      before:absolute
      before:top-0
      before:left-0
      before:w-full
      before:h-full
      before:rounded-sm
      before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]
      before:pointer-events-none
      before:z-[2]
      data-[active='true']:after:content-['']
      data-[active='true']:after:block
      data-[active='true']:after:absolute
      data-[active='true']:after:w-1.5
      data-[active='true']:after:h-1.5
      data-[active='true']:after:top-1/2
      data-[active='true']:after:left-1/2
      data-[active='true']:after:pointer-events-none
      data-[active='true']:after:rounded-full
      data-[active='true']:after:-translate-x-1/2
      data-[active='true']:after:-translate-y-1/2
      data-[active='true']:after:bg-white
      data-[active='true']:after:z-[2]
      data-[active='true']:after:ring-1
      data-[active='true']:after:ring-[rgba(0,0,0,0.33)]
      [&>canvas]:block
      [&>canvas]:w-full
      [&>canvas]:aspect-square
      [&>canvas]:rounded-sm
      [&>canvas:focus-visible]:outline-none
      [&>canvas:focus-visible]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(59,130,246,1)]
    `,
  },

  file: {
    label: '!text-sm !pt-1 !font-semibold',
    fileItem: 'flex items-center text-neutral mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer px-3 mt-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove:
      'relative z-[2] ml-auto text-[0px] hover:text-system-error pl-2',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    inner: 'relative cursor-pointer formkit-multiple:[&>button]:absolute',
    input:
      'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: `
      flex w-full items-center px-3 mt-6
    `,
    noFilesIcon: 'w-4 mr-2',
  },

  toggle: {
    wrapper: '$reset flex flex-wrap items-center relative',
    label: '$reset mb-0 text-dark dark:text-light',
    inner: '$reset inline-block mr-2',
    input: 'peer absolute opacity-0 pointer-events-none',
    innerLabel:
      'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
    thumb:
      'relative left-0 aspect-square rounded-full transition-all w-5 bg-light',
    track:
      'p-0.5 min-w-[3em] relative rounded-full transition-all bg-neutral peer-checked:bg-primary-dark-1 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
    valueLabel: 'font-bold text-sm',
  },

  stepNumber: {
    wrapper: '$reset grid grid-cols-[minmax(0,1fr),auto]',
    label: '$reset label inline-flex items-center',
    inner: `$reset h-10 w-[7.625rem] grid grid-cols-[2.5rem,auto,2.5rem] border border-neutral-dark-1 dark:border-neutral-light-1 rounded-md`,
    input: `$reset input input-step-number input-hover`,
    decrement: `input-step-number-button text-system-error rounded-l-md`,
    decrementIcon: 'size-6 fill-current',
    increment: `input-step-number-button text-system-success rounded-r-md`,
    incrementIcon: 'size-6 fill-current',
    messages: 'col-span-2',
  },

  select: {
    outer: '$reset group',
    wrapper: '',
    label: '$reset hidden',
    inner: '$reset flex flex-row py-2 justify-center items-center',
    prefixIcon: '$reset hidden',
    prefix: '$reset hidden',
    input:
      'px-3 block w-full text-lg  appearance-none bg-transparent border-none outline-none text-neutral-dark-1 dark:text-neutral-light-1',
    option: 'bg-body text-neutral-dark-1 dark:text-neutral-light-1 w-full',
    selectIcon: 'w-8 h-8 text-neutral-dark-1 dark:text-neutral-light-1',
    suffix: '$reset hidden',
    suffixIcon: '$reset hidden',
    help: '$reset hidden',
    messages: '$reset hidden',
    message: '$reset hidden',
  },
}
