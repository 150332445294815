import { bindings, defineFormKitConfig } from '@formkit/vue'
import { createValidationPlugin } from '@formkit/validation'
import {
  email as emailRule,
  required,
  confirm,
  contains_numeric,
  contains_symbol,
  contains_uppercase,
  contains_lowercase,
  contains_alphanumeric,
  length,
} from '@formkit/rules'
import { createI18nPlugin, de, es } from '@formkit/i18n'
import {
  createLibraryPlugin,
  text,
  form,
  group,
  number,
  checkbox,
  radio,
  submit,
  textarea,
  hidden,
  email,
  password,
  select,
} from '@formkit/inputs'
import {
  createProPlugin,
  toggle,
  dropdown,
  autocomplete,
  colorpicker,
} from '@formkit/pro'
import { generateClasses, createThemePlugin } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
import stepNumberInput from '@forms/components/input/step-number.vue'
import theme from './theme.js'

const library = createLibraryPlugin({
  text,
  group,
  checkbox,
  radio,
  form,
  textarea,
  number,
  submit,
  hidden,
  email,
  password,
  select,
})
const validation = createValidationPlugin({
  required,
  confirm,
  contains_numeric,
  contains_symbol,
  contains_uppercase,
  contains_lowercase,
  contains_alphanumeric,
  email: emailRule,
  length,
})
const i18n = createI18nPlugin({ de, es })
const proPlugin = createProPlugin('fk-daf990e47d', {
  toggle,
  dropdown,
  autocomplete,
  colorpicker,
})
// will be replaced with the real kh icons
const themePlugin = createThemePlugin('', {
  add: genesisIcons.add,
  arrowDown: genesisIcons.arrowDown,
  arrowUp: genesisIcons.arrowUp,
  check: genesisIcons.check,
  close: genesisIcons.close,
  checkboxDecorator: genesisIcons.checkboxDecorator,
  date: genesisIcons.date,
  fileItem: genesisIcons.fileItem,
  fileRemove: genesisIcons.fileRemove,
  noFiles: genesisIcons.noFiles,
  radioDecorator: genesisIcons.radioDecorator,
  select: genesisIcons.select,
  spinner: genesisIcons.spinner,
  star: genesisIcons.star,
  trash: genesisIcons.trash,
  fastForward: genesisIcons.fastForward,
  right: genesisIcons.right,
  left: genesisIcons.left,
  rewind: genesisIcons.rewind,
  color: genesisIcons.color,
})

export default defineFormKitConfig({
  autoImport: true,
  config: {
    classes: generateClasses(theme),
  },
  plugins: [library, validation, i18n, bindings, proPlugin, themePlugin],
  locales: { de, es },
  locale: 'de',
  inputs: {
    stepNumber: {
      component: stepNumberInput,
      props: ['disabled', 'min', 'max', 'step', 'deletable'],
    },
  },
})
